/** @jsxRuntime classic */
/** @jsx jsx */
import ExhibitorProductListPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorProductListPage';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export default function ExhibitorSidebar(props) {
  const { exhibitor } = afterQuery(props?.data);
  return (
    <Layout>
      <ExhibitorProductListPage exhibitor={exhibitor} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query california_ExhibitorProducts($partnerId: ID!) {
    bottlebooks {
      event(eventId: "62ecd29062842e10d25d8961", locale: en) {
        logo {
          fixed(width: 150) {
            src
            width
            height
          }
        }
      }
      oct31Morning: event(eventId: "62ecd29062842e10d25d8961", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
      oct31Afternoon: event(eventId: "62ecd2b3f28b1e10cc33eb5d", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
      nov1Morning: event(eventId: "62ecd2fe123f1410cadbbba4", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
      nov1Afternoon: event(eventId: "62ecd313f394f910d1d6d29f", locale: en) {
        ...Bottlebooks_ExhibitorProducts
      }
    }
  }

  fragment Bottlebooks_ExhibitorProducts on Bottlebooks_Event {
    collectionId
    exhibitor(exhibitorId: $partnerId, returnNullWhenNotFound: true) {
      stand {
        name
      }
      products {
        nodes {
          productId
          ...bb_ProductCard
          ... on Bottlebooks_Wine {
            shortName
            vintage
            grapeVarieties {
              varietyName
              percentage
            }
          }
          producer {
            name
          }
        }
      }
    }
  }
`;

function afterQuery(data) {
  const { bottlebooks } = data;
  const events = Object.values(bottlebooks);
  const bb_exhibitor = mergeBbExhibitor(events);
  const bb_products = bb_exhibitor?.products?.nodes;
  return {
    exhibitor: {
      ...bb_exhibitor,
      products: bb_products,
    },
  };
}

function mergeBbExhibitor(events) {
  const exhibitor = events.reduce((acc, event) => {
    const { exhibitor, registration } = event;
    if (!exhibitor) return acc;

    const { exhibitorId } = exhibitor;

    acc[exhibitorId] = {
      ...exhibitor,
      collectionIds: [event.collectionId],
      ...registration,
      ...registration?.profile,
      standNames: [
        ...(acc[exhibitorId]?.standNames || []),
        {
          collectionId: event.collectionId,
        },
      ],
      products: {
        nodes: [
          ...(acc[exhibitorId]?.products?.nodes || []),
          ...exhibitor.products.nodes.map((product) => ({
            ...product,
            collectionId: event.collectionId,
            standNames: [{ collectionId: event.collectionId }],
          })),
        ],
      },
    };
    return acc;
  }, {});

  return Object.values(exhibitor)[0];
}
